import React from 'react';
import styled from 'styled-components';
import Head from 'next/head';
import Header from './Header';
import Footer from './Footer';
import { DOMAIN } from '../../config';
import SignUpForm from '@components/Form/SignUpForm';
import { PRODUCT_DISPLAY_NAME } from '../../appConfig';
import AppHeader from '@components/Layout/AppHeader';
import { useClearSearchParams } from '@hooks/useExtendedSearchParams';
import { storeEmailAuthToken } from '@utils/index';

const Wrapper = styled.div`
  /* overflow: hidden; -> removing breaks position sticky can't default this behavior for all pages */
  overflow: ${(props) => (props.disableOverflow ? 'visible' : 'hidden')};
  background: ${({ theme }) => theme.themeColors.appBackground};
`;

const SignUpFormWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

const RouteWrapper = ({ children }) => {
  const clearSearchParams = useClearSearchParams();

  React.useEffect(() => {
    storeEmailAuthToken();
    clearSearchParams({ auth_id: true, token: true });
  }, []);

  return children;
};

const Layout = ({
  children,
  pageTitle,
  appHeader,
  description,
  shareImage,
  section,
  isDarkMode,
  useTransparentHeader,
  hasBorder,
  background,
  banner,
  headerBgColor,
  disableOverflow,
}) => {
  const {
    location: { href },
  } = window;

  const [showSignUpModal, setShowSignUpModal] = React.useState(false);
  const [urlParams, setUrlParams] = React.useState('');

  React.useEffect(() => {
    const params = href.split('?');
    if (params.length && params[1]) {
      setUrlParams(`?${params[1]}`);
    }
  }, []);

  const onShowSignUpModal = () => {
    setShowSignUpModal(true);
  };
  const onHideSignUpModal = () => {
    setShowSignUpModal(false);
  };

  return (
    <Wrapper disableOverflow={disableOverflow}>
      <Head>
        <title>
          {pageTitle ? `${PRODUCT_DISPLAY_NAME} - ${pageTitle}` : 'Tornado.com | Invest better than yesterday'}
        </title>
        <meta name="ir-site-verification-token" value="-225012027" />
        <meta
          name="Description"
          content={
            description ||
            'Tornado gives you the insights & community to turn your instincts into smart investing. Join today to sharpen your skills & grow your gains.'
          }
        />
        <meta property="og:site_name" content="TheStreet powered by Tornado" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://tornado.com/" />
        <meta property="og:title" content={pageTitle || 'Tornado.com | Invest better than yesterday'} />
        <meta
          property="og:description"
          content={
            description ||
            'Tornado gives you the insights & community to turn your instincts into smart investing. Join today to sharpen your skills & grow your gains.'
          }
        />
        <meta property="og:image" content={shareImage || 'https://tornado.com/images/learn-and-earn-program.png'} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://tornado.com/" />
        <meta property="twitter:title" content="Tornado.com | Invest better than yesterday" />
        <meta
          property="twitter:description"
          content={
            description ||
            'Tornado gives you the insights & community to turn your instincts into smart investing. Join today to sharpen your skills & grow your gains.'
          }
        />
        <meta property="twitter:image" content={shareImage || 'https://tornado.com/images/share-card.png'} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        {!process.env.ENVIRONMENT === 'production' && <meta name="robots" content="noindex, nofollow" />}

        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta charSet="utf-8" />
        <meta name="apple-itunes-app" content="app-id=1481266478, app-argument=nvstr" />
        <meta property="og:title" content="Smarter, Social Investing" />
        <meta property="og:description" content="Harness the power of technology and collective insights" />
        <meta property="og:url" content={DOMAIN} />
        <link rel="icon" href="/favicon-260.png" />

        <link rel="preload" href="/fonts/Bolton-Bold.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/Bolton-Medium.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/Bolton-Regular.woff2" as="font" type="font/woff2" crossOrigin="anonymous" />

        <link rel="preload" href="/fonts/Roboto-Bold.ttf" as="font" type="font/ttf" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/Roboto-Thin.ttf" as="font" type="font/ttf" crossOrigin="anonymous" />
        <link rel="preload" href="/fonts/Roboto-Regular.ttf" as="font" type="font/ttf" crossOrigin="anonymous" />
      </Head>
      {banner}
      {background}
      {showSignUpModal && (
        <SignUpFormWrapper>
          <SignUpForm
            isMobile={true}
            urlParams={urlParams}
            setShowSignUpModal={setShowSignUpModal}
            onShowSignUpModal={onShowSignUpModal}
            onHideSignUpModal={onHideSignUpModal}
          />
        </SignUpFormWrapper>
      )}
      {appHeader ? (
        <AppHeader section={section} hasBorder={false} />
      ) : (
        <Header
          section={section}
          isDarkMode={isDarkMode}
          hasBorder={hasBorder}
          useTransparentHeader={useTransparentHeader}
          urlParams={urlParams}
          showSignUpModal={showSignUpModal}
          onShowSignUpModal={onShowSignUpModal}
          forceBgColor={headerBgColor}
        />
      )}
      {children}
      <Footer />
    </Wrapper>
  );
};

export default Layout;
